import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "grid mr-0 mt-0 ml-0 justify-content-center" }
const _hoisted_2 = { class: "col-12 mr-0 mt-1 ml-0" }
const _hoisted_3 = { class: "sm:col-12 md:col-12 lg:col-12 col-12 p-0" }
const _hoisted_4 = { class: "customer-tab" }
const _hoisted_5 = { class: "menutab-item" }
const _hoisted_6 = { class: "menutab-item-link" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = ["href"]
const _hoisted_9 = { class: "menutab-item" }
const _hoisted_10 = { class: "menutab-item-link" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = ["href"]
const _hoisted_13 = { class: "customer-tab" }
const _hoisted_14 = { class: "align-self-center" }
const _hoisted_15 = { class: "col-12 md:col-12 lg:col-12 p-0" }
const _hoisted_16 = { class: "grid flex lg:hidden" }
const _hoisted_17 = { class: "flex col-12 justify-content-between" }
const _hoisted_18 = {
  class: "formgrid grid hidden lg:flex",
  style: {
                  alignItems: 'baseline',
                  position: 'sticky',
                  top: '3.25rem',
                  zIndex: 100,
                  background: '#fafafa',
                  width: '100%',
                  marginTop: '-3px',
                  paddingTop: '2px',
                  borderBottom: '1px solid #e0e0e0',
                }
}
const _hoisted_19 = { class: "col-11" }
const _hoisted_20 = { class: "menutab-item" }
const _hoisted_21 = { class: "col-1" }
const _hoisted_22 = { class: "sm:col-12 md:col-12 lg:col-12 ml-0 p-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_NewCustomer = _resolveComponent("NewCustomer")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Card = _resolveComponent("Card")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_TabView = _resolveComponent("TabView")!
  const _component_MenuBar = _resolveComponent("MenuBar")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_TopKPICards = _resolveComponent("TopKPICards")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Search, {
          label: "name",
          file: "CUST",
          currentView: "Customers",
          placeholder: "Enter Customer ID or Name",
          leftIcon: true,
          class: "large-search-input"
        }),
        _createVNode(_component_NewCustomer)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_TabView, {
          activeIndex: _ctx.active,
          onTabChange: _ctx.handleTabChange,
          scrollable: true,
          class: "customers-list-tabview"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_TabPanel, null, {
              header: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _cache[0] || (_cache[0] = _createElementVNode("span", { class: "line-height-4 pr-2" }, "Customers", -1)),
                  (_ctx.getAllCustomers.cust_items)
                    ? (_openBlock(), _createBlock(_component_Badge, {
                        key: 0,
                        class: "ml-2",
                        value: _ctx.getAllCustomers.cust_items.length
                      }, null, 8, ["value"]))
                    : _createCommentVNode("", true)
                ])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_TabView, {
                  class: "customers-menu-tabview",
                  onTabChange: _ctx.handleCustomerMenuTabChange,
                  activeIndex: _ctx.customerSearchTab
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_TabPanel, null, {
                      header: _withCtx(() => [
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("span", _hoisted_6, [
                            _createVNode(_component_Button, {
                              label: "Recently Viewed",
                              class: "menutab-button mr-2"
                            })
                          ])
                        ])
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_Card, null, {
                          content: _withCtx(() => [
                            _createVNode(_component_DataTable, {
                              dataKey: "cust_id",
                              value: _ctx.getRecentlyViewedCustomers,
                              paginator: true,
                              rowsPerPageOptions: [10, 25, 50],
                              rows: 10,
                              selectionMode: "single",
                              breakpoint: "1267px",
                              onRowClick: _ctx.handleCustomersRowClick,
                              class: "p-datatable-sm"
                            }, {
                              empty: _withCtx(() => _cache[1] || (_cache[1] = [
                                _createTextVNode(" No rows to show. ")
                              ])),
                              default: _withCtx(() => [
                                _createVNode(_component_Column, {
                                  field: "cust_id",
                                  header: "ID"
                                }),
                                _createVNode(_component_Column, {
                                  field: "name",
                                  header: "Customer Name"
                                }),
                                (_ctx.showServiceRep)
                                  ? (_openBlock(), _createBlock(_component_Column, {
                                      key: 0,
                                      field: "service_rep",
                                      header: "Service Rep"
                                    }))
                                  : (_openBlock(), _createBlock(_component_Column, {
                                      key: 1,
                                      field: "rep_items",
                                      header: "Reps"
                                    }, {
                                      body: _withCtx((slotProps) => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.data.rep_items, (rep, index) => {
                                          return (_openBlock(), _createElementBlock("span", { key: index }, [
                                            _createTextVNode(_toDisplayString(rep.rep_name) + " ", 1),
                                            (index < slotProps.data.rep_items.length - 1)
                                              ? (_openBlock(), _createElementBlock("br", _hoisted_7))
                                              : _createCommentVNode("", true)
                                          ]))
                                        }), 128))
                                      ]),
                                      _: 1
                                    })),
                                _createVNode(_component_Column, { header: "Shipping Address" }, {
                                  body: _withCtx((slotProps) => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shippingAddress(
                              slotProps.data,
                            ), (address, i) => {
                                      return (_openBlock(), _createElementBlock("span", { key: i }, [
                                        _createTextVNode(_toDisplayString(address) + " ", 1),
                                        _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1))
                                      ]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_Column, {
                                  field: "phone",
                                  header: "Phone #",
                                  bodyStyle: "text-align: right"
                                }, {
                                  body: _withCtx((slotProps) => [
                                    _createElementVNode("a", {
                                      href: 'tel:' + slotProps.data.phone
                                    }, _toDisplayString(slotProps.data.phone), 9, _hoisted_8)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_Column, { header: "Actions" }, {
                                  body: _withCtx((slotProps) => [
                                    _createVNode(_component_Button, {
                                      onClick: _withModifiers(($event: any) => (
                              _ctx.removeRecentlyViewedCustomer(
                                slotProps.data.cust_id,
                              )
                            ), ["stop"]),
                                      icon: "pi pi-trash",
                                      style: {"background-color":"red !important"}
                                    }, null, 8, ["onClick"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["value", "onRowClick"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_TabPanel, null, {
                      header: _withCtx(() => [
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("span", _hoisted_10, [
                            _createVNode(_component_Button, {
                              label: "Search Results",
                              class: "menutab-button",
                              badge: 
                          _ctx.getAllCustomers.cust_items &&
                          _ctx.getAllCustomers.cust_items.length.toString()
                        
                            }, null, 8, ["badge"])
                          ])
                        ])
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_Card, null, {
                          content: _withCtx(() => [
                            _createVNode(_component_DataTable, {
                              dataKey: "cust_id",
                              value: _ctx.getAllCustomers.cust_items,
                              paginator: true,
                              rowsPerPageOptions: [10, 25, 50],
                              rows: 10,
                              selectionMode: "single",
                              breakpoint: "1267px",
                              onRowClick: _ctx.handleCustomersRowClick,
                              class: "p-datatable-sm"
                            }, {
                              empty: _withCtx(() => _cache[3] || (_cache[3] = [
                                _createTextVNode(" No rows to show. ")
                              ])),
                              default: _withCtx(() => [
                                _createVNode(_component_Column, {
                                  field: "cust_id",
                                  header: "ID"
                                }),
                                _createVNode(_component_Column, {
                                  field: "name",
                                  header: "Customer Name"
                                }),
                                (_ctx.showServiceRep)
                                  ? (_openBlock(), _createBlock(_component_Column, {
                                      key: 0,
                                      field: "service_rep",
                                      header: "Service Rep"
                                    }))
                                  : (_openBlock(), _createBlock(_component_Column, {
                                      key: 1,
                                      field: "rep_items",
                                      header: "Reps"
                                    }, {
                                      body: _withCtx((slotProps) => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.data.rep_items, (rep, index) => {
                                          return (_openBlock(), _createElementBlock("span", { key: index }, [
                                            _createTextVNode(_toDisplayString(rep.rep_name) + " ", 1),
                                            (index < slotProps.data.rep_items.length - 1)
                                              ? (_openBlock(), _createElementBlock("br", _hoisted_11))
                                              : _createCommentVNode("", true)
                                          ]))
                                        }), 128))
                                      ]),
                                      _: 1
                                    })),
                                _createVNode(_component_Column, { header: "Shipping Address" }, {
                                  body: _withCtx((slotProps) => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shippingAddress(
                              slotProps.data,
                            ), (address, i) => {
                                      return (_openBlock(), _createElementBlock("span", { key: i }, [
                                        _createTextVNode(_toDisplayString(address) + " ", 1),
                                        _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1))
                                      ]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_Column, {
                                  field: "phone",
                                  header: "Phone #",
                                  bodyStyle: "text-align: right"
                                }, {
                                  body: _withCtx((slotProps) => [
                                    _createElementVNode("a", {
                                      href: 'tel:' + slotProps.data.phone
                                    }, _toDisplayString(slotProps.data.phone), 9, _hoisted_12)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["value", "onRowClick"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["onTabChange", "activeIndex"])
              ]),
              _: 1
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customerInquiry.CustomerMap, (tab, index) => {
              return (_openBlock(), _createBlock(_component_TabPanel, {
                key: tab.customer.cust_id
              }, {
                header: _withCtx(() => [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("span", _hoisted_14, _toDisplayString(tab.customer.name), 1),
                    _createVNode(_component_Button, {
                      icon: "pi pi-times",
                      class: "p-button-text",
                      onClick: _withModifiers(($event: any) => (_ctx.removeCustomer(tab)), ["stop"])
                    }, null, 8, ["onClick"])
                  ])
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, [
                      _createVNode(_component_MenuBar, {
                        class: "col-12 p-2 font-semibold collapsible-mobile-view",
                        model: _ctx.visibleMenuItems
                      }, null, 8, ["model"]),
                      _createElementVNode("div", _hoisted_17, [
                        _createVNode(_component_Button, {
                          label: "Save",
                          icon: "pi pi-save",
                          iconPos: "left",
                          class: "text-sm font-bold mt-2 align-self-center collapsible-mobile-view",
                          style: {"height":"40px"},
                          id: "button-save-customer",
                          loading: _ctx.loadingSaveCustomer,
                          onClick: _ctx.saveCustomer
                        }, null, 8, ["loading", "onClick"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("div", _hoisted_19, [
                        _createVNode(_component_TabView, {
                          scrollable: true,
                          class: "customers-menu-tabview tabview-panels-hidden",
                          "active-index": _ctx.activeInnerTab,
                          onTabChange: _ctx.handleInnerTabChange
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleMenuItems, (option) => {
                              return (_openBlock(), _createBlock(_component_TabPanel, {
                                key: option.label
                              }, {
                                header: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_20, [
                                    _createVNode(_component_router_link, {
                                      to: option.to,
                                      class: "menutab-item-link",
                                      "data-testid": option?.dataTestId
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_Button, {
                                          label: option.label,
                                          class: "menutab-button",
                                          onClick: ($event: any) => (_ctx.saveInnerTabItem(option.to)),
                                          badge: _ctx.getBadgeCount(option.badge, tab, index)
                                        }, null, 8, ["label", "onClick", "badge"])
                                      ]),
                                      _: 2
                                    }, 1032, ["to", "data-testid"])
                                  ])
                                ]),
                                _: 2
                              }, 1024))
                            }), 128))
                          ]),
                          _: 2
                        }, 1032, ["active-index", "onTabChange"])
                      ]),
                      _createElementVNode("div", _hoisted_21, [
                        _createVNode(_component_Button, {
                          label: "Save",
                          icon: "pi pi-save",
                          iconPos: "left",
                          class: "text-sm font-bold mt-2 align-self-center",
                          style: {"height":"40px"},
                          id: "button-save-customer",
                          loading: _ctx.loadingSaveCustomer,
                          onClick: _ctx.saveCustomer
                        }, null, 8, ["loading", "onClick"])
                      ])
                    ]),
                    _createElementVNode("div", null, [
                      (_ctx.$route.fullPath == '/customers/general')
                        ? (_openBlock(), _createBlock(_component_TopKPICards, {
                            key: 0,
                            customerId: _ctx.activeTabCustomerId,
                            menuItems: _ctx.visibleMenuItems,
                            onCardClicked: _ctx.handleCardClick
                          }, null, 8, ["customerId", "menuItems", "onCardClicked"]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_22, [
                      _createVNode(_component_router_view, {
                        index: index,
                        "current-view": "customers",
                        "current-customer": _ctx.getCurrentCustomer,
                        onUpdateBadge: 
                    (field, value) =>
                      _ctx.updateBadge(index, field, value)
                  
                      }, null, 8, ["index", "current-customer", "onUpdateBadge"])
                    ])
                  ])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["activeIndex", "onTabChange"])
      ])
    ])
  ]))
}